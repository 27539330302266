
import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, CircularProgress, Container, Stack, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

interface Errors {
  airlineError:boolean,
  flightNumberError:boolean,
  dateError:boolean
}

interface Airline {
    name: string,
    iata_code: string|null|undefined,
    icao_code: string|null|undefined
}

function FlightSearch() {
  const [airline, setAirline] = useState("");
  const [flightNumber, setFlightNumer] = useState("");
  const [date, setDate] = useState<Dayjs | null>(dayjs());
  const [errors, setErrors] = useState<Errors>({airlineError: false, flightNumberError: false, dateError: false});
  const [airlineOptions, setAirlineOptions] = useState<Airline[]>([]);
  const [loading, setLoading] = useState(false);

  const handleDateChange = (date: Dayjs | null) => {
    setDate(date);
    setErrors({...errors, dateError: !date});
  };

  const handleAirlineChange = (event: React.SyntheticEvent<Element, Event>, value: string) => {
    if (value.length > 1) {
      setLoading(true);

      (async () => {
        const response = await fetch('/airlines?name=' + value);
        const airlines:Airline[] = await response.json();

        setAirlineOptions(airlines);
        setLoading(false);
      })();
    }
    setAirline(value);
    setErrors({...errors, airlineError: !value});
  }

  const handleFlightNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFlightNumer(event.target.value);
    setErrors({...errors, flightNumberError: !event.target.value});
  }

  const handleFlightDetailClick = () => {
    setErrors({
      airlineError: !airline,
      flightNumberError: !flightNumber,
      dateError: !date
    });
  }

const airlineSelected = (event: React.SyntheticEvent<Element, Event>, value: Airline|null) => {
    console.log(JSON.stringify(value));
}
  return (
    <Container maxWidth="xs">
      <Stack spacing={3}>
        <Autocomplete
          id="combo-box-demo"
          options={airlineOptions}
          getOptionLabel={(option) => option.iata_code ? `${option.name} (${option.iata_code})` : `${option.name}`}
          loading={loading}
          onInputChange={handleAirlineChange}
          onChange={airlineSelected}
          renderInput={(params) => (
            <TextField
              {...params}
              name="airline"
              label="Airline"
              value={airline}
              error={errors.airlineError}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />)}
        />

        <TextField name="flight_number" label="Flight Number" value={flightNumber} onChange={handleFlightNumberChange} error={errors.flightNumberError}/>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
              label="Flight Date"
              inputFormat="MM/DD/YYYY"
              value={date}
              onChange={handleDateChange}
              renderInput={(params) => <TextField {...params} error={errors.dateError}/>}
            />
        </LocalizationProvider>
      </Stack>
      <Button fullWidth size="large" type="submit" variant="contained" sx={{mt: 3}} onClick={handleFlightDetailClick}>
        Flight Details
      </Button>
    </Container>
  )
}

export default FlightSearch;