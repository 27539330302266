import { ReactNode, SyntheticEvent, useState } from 'react';
import { Tab, Tabs, Typography, Box } from '@mui/material';
import FlightSearch from '../components/FlightSearch';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography component={'span'} alignContent={'center'}>{children}</Typography>
            </Box>
        )}
        </div>
    );
}

function Home() {
    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 600 }}>
                <Tabs value={value} onChange={handleChange} centered>
                    <Tab label="Flights" sx={{ marginLeft: 1, marginRight: 1 }} />
                    <Tab label="Airports" sx={{ marginLeft: 1, marginRight: 1 }} />
                    <Tab label="Routes" sx={{ marginLeft: 1, marginRight: 1 }} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <FlightSearch />
            </TabPanel>
            <TabPanel value={value} index={1}>
                Item Two
            </TabPanel>
            <TabPanel value={value} index={2}>
                Item Three
            </TabPanel>
        </Box>
    );
}

export default Home;