import React from 'react';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import ComingSoon from './pages/ComingSoon';
import { Grid } from '@mui/material';

function App() {
  return (
    <React.StrictMode>
      <Grid container justifyContent="center" alignItems="center">
        <Routes>
          <Route path="/" element={<ComingSoon />} />
          <Route path="/home" element={<Home />} />
        </Routes>
      </Grid>
    </React.StrictMode>
  );
}

export default App;
